import { createApp } from "vue";
import { router } from "@/router";
import App from "./App.vue";
import VueGtag, { trackRouter } from "vue-gtag-next";

const app = createApp(App);

app.use(VueGtag, {
  property: {
    id: "G-C27NZWF049"
  }
});
trackRouter(router);
app.use(router);

app.mount("#app");
