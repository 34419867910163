<template>
  <layout show-tabbar>
    <van-list
      :loading="loading"
      :error="error !== null"
      :finished="true"
      error-text="请求失败，点击重新加载"
    >
      <template #default>
        <van-skeleton v-if="loading" :row="3" />
        <van-empty
          v-if="!loading && assets.length === 0"
          description="暂无已订阅课程"
        />
        <van-cell
          v-for="asset in assets"
          :key="asset.id"
          :to="'/course/' + asset.course.id"
        >
          <template #title>
            <div style="text-align: left; margin-left: 10px">
              <div>{{ asset.course.title }}</div>
              <div style="font-size: 0.8rem; color:gray">
                <div>已更新{{ asset.course.lessons.length }}期</div>
                <div>过期时间:{{ formatDate(asset.expireAt) }}</div>
              </div>
            </div>
          </template>
          <template #icon>
            <van-image width="150" :src="asset.course.coverUrl"></van-image>
          </template>
        </van-cell>
      </template>
    </van-list>
  </layout>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { useQuery, useResult } from "@vue/apollo-composable";
import { List, Cell, Image, Empty, Skeleton } from "vant";
import Layout from "@/components/Layout.vue";
import { GET_ASSETS } from "@/graphql/query/assets";
import { getAssets } from "@/graphql/generated/getAssets";

export default defineComponent({
  name: "AssetList",
  components: {
    layout: Layout,
    [List.name]: List,
    [Cell.name]: Cell,
    [Image.name]: Image,
    [Empty.name]: Empty,
    [Skeleton.name]: Skeleton
  },
  setup() {
    const { result, loading, error } = useQuery<getAssets>(GET_ASSETS);
    const assets = useResult(result, [], data => data.viewer?.assets);
    return {
      assets,
      loading,
      error
    };
  },
  methods: {
    formatDate(date: string): string {
      return new Date(date).toLocaleDateString("zh-CN");
    }
  }
});
</script>
