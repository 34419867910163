<template>
  <layout>
    <van-skeleton v-if="loading" title :row="3" />
    <van-empty v-else-if="error" :description="error.message" />
    <template v-else>
      <van-image :src="course.coverUrl" class="course-image" />
      <van-tabs v-model:active="activeName" sticky border>
        <van-tab title="专栏介绍" name="description">
          <van-cell>
            <h2>{{ course.title }}</h2></van-cell
          >
          <div class="course-description" v-html="course.description"></div>
        </van-tab>
        <van-tab title="专栏目录" name="index"
          ><van-list v-model:loading="loading" :finished="true">
            <van-cell
              v-for="lesson in course.lessons"
              :key="lesson.id"
              :title="lesson.title"
              :label="formatDuration(lesson.duration)"
              :to="'/lesson/' + lesson.id"
              :center="true"
            >
              <template #right-icon>
                <van-tag v-show="lesson.free" plain type="danger"
                  >免费试看</van-tag
                >
              </template>
            </van-cell>
          </van-list>
        </van-tab>
      </van-tabs>
    </template>
  </layout>
</template>

<script lang="ts">
import { Empty, Image, Skeleton, Tabs, Tab, List, Cell, Tag } from "vant";
import { defineComponent, ref } from "vue";
import Layout from "@/components/Layout.vue";
import { useQuery, useResult } from "@vue/apollo-composable";
import { GET_COURSE } from "@/graphql/query/course";
import { getCourse } from "@/graphql/generated/getCourse";
import { useRouter } from "vue-router";
import { formatDuration } from "@/utils";

export default defineComponent({
  name: "Course",
  components: {
    Layout,
    [Image.name]: Image,
    [Skeleton.name]: Skeleton,
    [Empty.name]: Empty,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [List.name]: List,
    [Cell.name]: Cell,
    [Tag.name]: Tag
  },
  setup: function() {
    const router = useRouter();
    const activeName = ref("index");
    const { result, loading, error } = useQuery<getCourse>(GET_COURSE, {
      id: router.currentRoute.value.params.id
    });
    const course = useResult(result, null, data => data.course);
    return {
      loading,
      error,
      course,
      activeName
    };
  },
  methods: {
    formatDuration: formatDuration
  }
});
</script>

<style scoped>
h2 {
  font-size: 1rem;
}
.course-image {
  width: 100%;
  /*height: 56.25vw;*/
}

.course-description {
  margin: 5px;
}

.course-description >>> img {
  max-width: 100%;
}

.course-description >>> blockquote {
  margin: 5px;
}
</style>
