
import { defineComponent, watch } from "vue";
import { Form, Field, Button, CountDown, Toast, Image, Row, Col } from "vant";
import gql from "graphql-tag";
import { useMutation } from "@vue/apollo-composable";
import Layout from "../components/Layout.vue";
import { useGtag } from "vue-gtag-next";

export default defineComponent({
  name: "Login",
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
    [CountDown.name]: CountDown,
    [Image.name]: Image,
    [Row.name]: Row,
    [Col.name]: Col,
    layout: Layout
  },
  setup() {
    const {
      mutate: sendCodeMutation,
      loading: sendCodeLoading,
      error: sendCodeError
    } = useMutation(gql`
      mutation sendCode($mobile: String!) {
        sendCode(mobile: $mobile)
      }
    `);
    const {
      mutate: loginMobileMutation,
      loading: loginMobileLoading,
      error: loginMobileError
    } = useMutation(gql`
      mutation loginMobile($mobile: String!, $code: String!) {
        loginMobile(mobile: $mobile, code: $code) {
          token
        }
      }
    `);
    watch(sendCodeError, error => error && Toast.fail(error.message));
    watch(loginMobileError, error => error && Toast.fail(error.message));
    return {
      sendCodeMutation,
      sendCodeLoading,
      sendCodeError,
      loginMobileMutation,
      loginMobileLoading,
      loginMobileError
    };
  },
  data: function() {
    return {
      mobile: "",
      code: "",
      time: 0,
      isCountDownActive: false
    };
  },
  methods: {
    startTimer: function() {
      this.time = 60 * 1000; // 60 seconds
      this.isCountDownActive = true;
    },
    finishTimer: function() {
      this.isCountDownActive = false;
    },
    onSubmit: async function() {
      const { event } = useGtag();
      const { data } = await this.loginMobileMutation({
        mobile: this.mobile,
        code: this.code
      });
      if (data?.loginMobile?.token) {
        event("login", {
          event_category: "login",
          event_label: this.mobile
        });
        localStorage.setItem("token", data.loginMobile.token);
        Toast.success("登录成功");
        await this.$router.push("/assets");
      }
    },
    sendCode: async function() {
      const loginForm = this.$refs.loginForm as typeof Form;
      try {
        await loginForm.validate("mobile");
      } catch (e) {
        return;
      }
      const { data } = await this.sendCodeMutation({ mobile: this.mobile });
      if (data) {
        this.startTimer();
      }
    }
  }
});
