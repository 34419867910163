
import { defineComponent } from "vue";
import { Icon, Cell, Button, Skeleton, Empty } from "vant";
import Layout from "../components/Layout.vue";
import { useQuery, useResult } from "@vue/apollo-composable";
import { GET_ME } from "@/graphql/query/me";
import { getMe } from "@/graphql/generated/getMe";
import { useGtag } from "vue-gtag-next";

export default defineComponent({
  name: "Me",
  components: {
    layout: Layout,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Skeleton.name]: Skeleton,
    [Empty.name]: Empty
  },
  setup: function() {
    const { result, loading, error } = useQuery<getMe>(GET_ME);
    const viewer = useResult(result);
    return {
      loading,
      error,
      viewer
    };
  },
  methods: {
    async logOut() {
      const { event } = useGtag();
      event("logout", {
        event_category: "engagement",
        event_label: this.viewer?.mobile
      });
      localStorage.removeItem("token");
      await this.$router.push("/");
    }
  }
});
