<template>
  <layout>
    <van-row>
      <van-col span="6"></van-col>
      <van-col span="4">
        <van-image
          :src="require('../assets/logo.png')"
          width="2rem"
          height="2rem"
      /></van-col>
      <van-col span="8"><h2>耶加阅读</h2></van-col>
      <van-col span="6"></van-col>
    </van-row>
    <van-form
      @submit="onSubmit"
      validate-first
      validate-trigger="onSubmit"
      ref="loginForm"
    >
      <van-field
        v-model="mobile"
        name="mobile"
        type="tel"
        label="手机号"
        center
        clearable
        placeholder="请输入手机号"
        :rules="[{ pattern: /^1[345789]\d{9}$/, message: '手机号格式不正确' }]"
      />
      <van-field
        v-model="code"
        type="digit"
        name="code"
        center
        clearable
        label="短信验证码"
        placeholder="请输入短信验证码"
        :rules="[{ pattern: /^\d{6}$/, message: '验证码格式不正确' }]"
      >
        <template #button>
          <van-count-down
            :time="time"
            @finish="finishTimer"
            v-if="isCountDownActive"
          >
            <template #default="timeData">
              <van-button size="small" type="primary" disabled
                >{{ timeData.seconds }}s 后可重发</van-button
              >
            </template>
          </van-count-down>
          <van-button
            v-else
            size="small"
            type="primary"
            @click="sendCode"
            :loading="sendCodeLoading"
          >
            发送验证码
          </van-button>
        </template>
      </van-field>
      <div style="margin: 16px;">
        <van-button
          round
          block
          type="info"
          native-type="submit"
          :loading="loginMobileLoading"
          >提交</van-button
        >
      </div>
    </van-form>
  </layout>
</template>

<script lang="ts">
import { defineComponent, watch } from "vue";
import { Form, Field, Button, CountDown, Toast, Image, Row, Col } from "vant";
import gql from "graphql-tag";
import { useMutation } from "@vue/apollo-composable";
import Layout from "../components/Layout.vue";
import { useGtag } from "vue-gtag-next";

export default defineComponent({
  name: "Login",
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
    [CountDown.name]: CountDown,
    [Image.name]: Image,
    [Row.name]: Row,
    [Col.name]: Col,
    layout: Layout
  },
  setup() {
    const {
      mutate: sendCodeMutation,
      loading: sendCodeLoading,
      error: sendCodeError
    } = useMutation(gql`
      mutation sendCode($mobile: String!) {
        sendCode(mobile: $mobile)
      }
    `);
    const {
      mutate: loginMobileMutation,
      loading: loginMobileLoading,
      error: loginMobileError
    } = useMutation(gql`
      mutation loginMobile($mobile: String!, $code: String!) {
        loginMobile(mobile: $mobile, code: $code) {
          token
        }
      }
    `);
    watch(sendCodeError, error => error && Toast.fail(error.message));
    watch(loginMobileError, error => error && Toast.fail(error.message));
    return {
      sendCodeMutation,
      sendCodeLoading,
      sendCodeError,
      loginMobileMutation,
      loginMobileLoading,
      loginMobileError
    };
  },
  data: function() {
    return {
      mobile: "",
      code: "",
      time: 0,
      isCountDownActive: false
    };
  },
  methods: {
    startTimer: function() {
      this.time = 60 * 1000; // 60 seconds
      this.isCountDownActive = true;
    },
    finishTimer: function() {
      this.isCountDownActive = false;
    },
    onSubmit: async function() {
      const { event } = useGtag();
      const { data } = await this.loginMobileMutation({
        mobile: this.mobile,
        code: this.code
      });
      if (data?.loginMobile?.token) {
        event("login", {
          event_category: "login",
          event_label: this.mobile
        });
        localStorage.setItem("token", data.loginMobile.token);
        Toast.success("登录成功");
        await this.$router.push("/assets");
      }
    },
    sendCode: async function() {
      const loginForm = this.$refs.loginForm as typeof Form;
      try {
        await loginForm.validate("mobile");
      } catch (e) {
        return;
      }
      const { data } = await this.sendCodeMutation({ mobile: this.mobile });
      if (data) {
        this.startTimer();
      }
    }
  }
});
</script>

<style scoped>
h2 {
  margin: 0.1rem;
  font-size: 1.5rem;
}
</style>
