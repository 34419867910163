
import { Empty, Image, Skeleton, Tabs, Tab, List, Cell, Tag } from "vant";
import { defineComponent, ref } from "vue";
import Layout from "@/components/Layout.vue";
import { useQuery, useResult } from "@vue/apollo-composable";
import { GET_COURSE } from "@/graphql/query/course";
import { getCourse } from "@/graphql/generated/getCourse";
import { useRouter } from "vue-router";
import { formatDuration } from "@/utils";

export default defineComponent({
  name: "Course",
  components: {
    Layout,
    [Image.name]: Image,
    [Skeleton.name]: Skeleton,
    [Empty.name]: Empty,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [List.name]: List,
    [Cell.name]: Cell,
    [Tag.name]: Tag
  },
  setup: function() {
    const router = useRouter();
    const activeName = ref("index");
    const { result, loading, error } = useQuery<getCourse>(GET_COURSE, {
      id: router.currentRoute.value.params.id
    });
    const course = useResult(result, null, data => data.course);
    return {
      loading,
      error,
      course,
      activeName
    };
  },
  methods: {
    formatDuration: formatDuration
  }
});
