import gql from "graphql-tag";

export const GET_ME = gql`
  query getMe {
    viewer {
      id
      mobile
    }
  }
`;
