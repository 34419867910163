<template>
  <layout show-tabbar>
    <van-skeleton v-if="loading" title :row="3" />
    <van-empty v-else-if="error" :description="error.message" />
    <template v-else>
      <div style="text-align: center;margin-top:20px">
        <van-icon name="user-o" size="45" />
        <div>{{ viewer?.mobile }}</div>
      </div>
      <van-cell title="我的课程" is-link to="/assets" />
      <div style="text-align: center; margin-top:60px">
        <van-button type="warning" @click="logOut">退出登录</van-button>
      </div>
    </template>
  </layout>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { Icon, Cell, Button, Skeleton, Empty } from "vant";
import Layout from "../components/Layout.vue";
import { useQuery, useResult } from "@vue/apollo-composable";
import { GET_ME } from "@/graphql/query/me";
import { getMe } from "@/graphql/generated/getMe";
import { useGtag } from "vue-gtag-next";

export default defineComponent({
  name: "Me",
  components: {
    layout: Layout,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Skeleton.name]: Skeleton,
    [Empty.name]: Empty
  },
  setup: function() {
    const { result, loading, error } = useQuery<getMe>(GET_ME);
    const viewer = useResult(result);
    return {
      loading,
      error,
      viewer
    };
  },
  methods: {
    async logOut() {
      const { event } = useGtag();
      event("logout", {
        event_category: "engagement",
        event_label: this.viewer?.mobile
      });
      localStorage.removeItem("token");
      await this.$router.push("/");
    }
  }
});
</script>
