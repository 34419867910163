
import { defineComponent } from "vue";
import Tabbar from "./Tabbar.vue";

export default defineComponent({
  name: "Layout",
  components: {
    "tab-bar": Tabbar
  },
  props: {
    showTabbar: Boolean
  }
});
