
import {
  Empty,
  Image,
  Skeleton,
  Tabs,
  Tab,
  List,
  Cell,
  Tag,
  Icon,
  ActionBar,
  ActionBarIcon,
  ActionBarButton
} from "vant";
import { defineComponent, watch } from "vue";
import Layout from "@/components/Layout.vue";
import { useQuery, useResult } from "@vue/apollo-composable";
import { RouterLink, useRouter } from "vue-router";
import { getLesson } from "@/graphql/generated/getLesson";
import { GET_LESSON } from "@/graphql/query/lesson";
import { isApple } from "@/utils";
import { useGtag } from "vue-gtag-next";

const HlsPlayer = require("xgplayer-hls.js");

export default defineComponent({
  name: "Lesson",
  components: {
    Layout,
    [Image.name]: Image,
    [Skeleton.name]: Skeleton,
    [Empty.name]: Empty,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [List.name]: List,
    [Cell.name]: Cell,
    [Tag.name]: Tag,
    [Icon.name]: Icon,
    [ActionBar.name]: ActionBar,
    [ActionBarIcon.name]: ActionBarIcon,
    [ActionBarButton.name]: ActionBarButton,
    "router-link": RouterLink
  },
  setup: function() {
    let player: any;
    const router = useRouter();
    const { event } = useGtag();
    const { result, loading, error } = useQuery<getLesson>(
      GET_LESSON,
      {
        id: router.currentRoute.value.params.id
      },
      { fetchPolicy: "network-only" }
    );
    const lesson = useResult(result, null, data => data.lesson);
    watch(lesson, lesson => {
      if (lesson && lesson.playUrl) {
        setTimeout(() => {
          if (player) {
            player.destroy();
          }
          player = new HlsPlayer({
            id: "player",
            fluid: true,
            playsinline: true,
            poster: lesson.coverUrl ?? "",
            url: lesson.playUrl ?? "",
            ...(!isApple() ? { "x5-video-player-type": "h5" } : null)
          });
          player.on("play", function() {
            event("play", {
              event_category: lesson.course.title,
              event_label: lesson.title
            });
          });
        }, 0);
      }
    });

    return { loading, error, lesson };
  }
});
