<template>
  <router-view></router-view>
</template>

<script lang="ts">
import { defineComponent, provide } from "vue";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { apolloClient } from "./apollo-client";
export default defineComponent({
  name: "App",
  setup() {
    provide(DefaultApolloClient, apolloClient);
  }
});
</script>

<style>
#app {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Helvetica Neue",
    Helvetica, Segoe UI, Arial, Roboto, "PingFang SC", "miui",
    "Hiragino Sans GB", "Microsoft Yahei", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  max-width: 768px;
  margin: 0 auto;
}
</style>
