<template>
  <layout>
    <van-skeleton v-if="loading" title :row="3" />
    <van-empty v-else-if="error" :description="error.message" />
    <template v-else>
      <div v-if="lesson.playUrl" id="player"></div>
      <van-image v-else class="lesson-cover" :src="lesson.coverUrl">
        <router-link to="/login">
          <div class="lesson-cover-overlay">
            <div class="overlay-content">
              <van-icon name="lock" />
              <div>当前内容订阅专栏后才能观看</div>
              <div>已订阅用户请点此登录</div>
            </div>
          </div>
        </router-link>
      </van-image>

      <van-cell>
        <template #title>
          <h2>{{ lesson.title }}</h2>
        </template>
      </van-cell>
      <van-cell
        center
        :is-link="false"
        :to="'/course/' + lesson.course.id"
        class="course-cell"
      >
        <template #icon>
          <van-image
            class="course-cover"
            :src="lesson.course.coverUrl"
            width="80"
            fit="contain"
          />
        </template>
        <template #title>
          <div class="course-title">{{ lesson.course.title }} &gt;</div>
          <div class="lessons-count">
            已更新{{ lesson.course.lessons.length }}期
          </div>
        </template>
      </van-cell>
      <van-cell>
        <template #title>
          <div class="lesson-description" v-html="lesson.description"></div>
        </template>
      </van-cell>
      <van-action-bar v-if="!lesson.playUrl">
        <!--        <van-action-bar-icon icon="chat-o" text="咨询英语启蒙" />-->
        <van-action-bar-icon icon="shop-o" text="主页" to="/" />
        <van-action-bar-button
          type="danger"
          text="查看专栏"
          :to="'/course/' + lesson.course.id"
        />
      </van-action-bar>
    </template>
  </layout>
</template>

<script lang="ts">
import {
  Empty,
  Image,
  Skeleton,
  Tabs,
  Tab,
  List,
  Cell,
  Tag,
  Icon,
  ActionBar,
  ActionBarIcon,
  ActionBarButton
} from "vant";
import { defineComponent, watch } from "vue";
import Layout from "@/components/Layout.vue";
import { useQuery, useResult } from "@vue/apollo-composable";
import { RouterLink, useRouter } from "vue-router";
import { getLesson } from "@/graphql/generated/getLesson";
import { GET_LESSON } from "@/graphql/query/lesson";
import { isApple } from "@/utils";
import { useGtag } from "vue-gtag-next";

const HlsPlayer = require("xgplayer-hls.js");

export default defineComponent({
  name: "Lesson",
  components: {
    Layout,
    [Image.name]: Image,
    [Skeleton.name]: Skeleton,
    [Empty.name]: Empty,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [List.name]: List,
    [Cell.name]: Cell,
    [Tag.name]: Tag,
    [Icon.name]: Icon,
    [ActionBar.name]: ActionBar,
    [ActionBarIcon.name]: ActionBarIcon,
    [ActionBarButton.name]: ActionBarButton,
    "router-link": RouterLink
  },
  setup: function() {
    let player: any;
    const router = useRouter();
    const { event } = useGtag();
    const { result, loading, error } = useQuery<getLesson>(
      GET_LESSON,
      {
        id: router.currentRoute.value.params.id
      },
      { fetchPolicy: "network-only" }
    );
    const lesson = useResult(result, null, data => data.lesson);
    watch(lesson, lesson => {
      if (lesson && lesson.playUrl) {
        setTimeout(() => {
          if (player) {
            player.destroy();
          }
          player = new HlsPlayer({
            id: "player",
            fluid: true,
            playsinline: true,
            poster: lesson.coverUrl ?? "",
            url: lesson.playUrl ?? "",
            ...(!isApple() ? { "x5-video-player-type": "h5" } : null)
          });
          player.on("play", function() {
            event("play", {
              event_category: lesson.course.title,
              event_label: lesson.title
            });
          });
        }, 0);
      }
    });

    return { loading, error, lesson };
  }
});
</script>

<style scoped>
.lesson-cover {
  position: relative;
}
.lesson-cover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  display: flex;
  align-items: center;
  align-content: center;
}

.overlay-content {
  width: 100%;
  text-align: center;
}
h2 {
  font-size: 1rem;
}

.course-cell {
  margin: 5px 0;
}
.course-cover {
  margin-right: 10px;
}
.course-title {
  font-size: 0.9rem;
}
.lessons-count {
  color: gray;
  font-size: 0.7rem;
}
.lesson-description {
  margin: 5px;
}
.lesson-description >>> img {
  max-width: 100%;
}
</style>
