
import { defineComponent } from "vue";
import Layout from "../components/Layout.vue";
import { Button, NoticeBar } from "vant";

export default defineComponent({
  name: "Home",
  components: {
    layout: Layout,
    [Button.name]: Button,
    [NoticeBar.name]: NoticeBar
  }
});
