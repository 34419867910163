import Course from "./pages/course.vue";
import Lesson from "./pages/lesson.vue";
import AssetList from "./pages/asset-list.vue";
import Login from "./pages/login.vue";
import Home from "./pages/home.vue";
import Me from "./pages/me.vue";
import NotFoundPage from "./pages/404.vue";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  { path: "/login", component: Login },
  { path: "/course/:id", component: Course },
  { path: "/lesson/:id", component: Lesson },
  { path: "/", component: Home },
  { path: "/assets", component: AssetList },
  { path: "/me", component: Me },
  { path: "/:catchAll(.*)", component: NotFoundPage }
];

export const router = createRouter({
  history: createWebHistory(),
  routes
});
