export const formatDuration = (duration: number) => {
  const minutes = Math.floor(duration / 60);
  const seconds = Math.floor(duration - minutes * 60);
  return minutes > 0 ? `${minutes}分${seconds}秒` : `${seconds}秒`;
};

export const isApple = () => {
  if (typeof navigator !== `undefined`) {
    const ua = navigator.userAgent.toLowerCase();
    return /iphone|ipod|ipad/.test(ua);
  }
  return false;
};

export const isWechat = () => {
  if (typeof navigator !== `undefined`) {
    const ua = navigator.userAgent.toLowerCase();
    return /micromessenger/.test(ua);
  }
  return false;
};
