import gql from "graphql-tag";

export const GET_COURSE = gql`
  query getCourse($id: ID!) {
    course(id: $id) {
      id
      coverUrl
      listPrice
      price
      title
      description
      free
      lessons {
        id
        title
        duration
        free
      }
    }
  }
`;
