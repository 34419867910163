
import { defineComponent } from "vue";
import Layout from "../components/Layout.vue";
import { Empty } from "vant";

export default defineComponent({
  name: "NotFound",
  components: {
    layout: Layout,
    [Empty.name]: Empty
  }
});
