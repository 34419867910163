import gql from "graphql-tag";

export const GET_ASSETS = gql`
  query getAssets {
    viewer {
      id
      assets {
        id
        expireAt
        course {
          id
          title
          coverUrl
          lessons {
            id
            duration
          }
        }
      }
    }
  }
`;
