
import { defineComponent, provide } from "vue";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { apolloClient } from "./apollo-client";
export default defineComponent({
  name: "App",
  setup() {
    provide(DefaultApolloClient, apolloClient);
  }
});
