import {
  ApolloClient,
  createHttpLink,
  InMemoryCache
} from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import { router } from "./router";
import { setContext } from "@apollo/client/link/context";

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      token: token ?? ""
    }
  };
});

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_API_ENDPOINT
});

const errorHandlingLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path, extensions }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      if (extensions?.code === "UNAUTHENTICATED") {
        router.push("/login");
      }
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const cache = new InMemoryCache();

export const apolloClient = new ApolloClient({
  link: errorHandlingLink.concat(authLink).concat(httpLink),
  cache
});
