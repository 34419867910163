<template>
  <layout show-tabbar>
    <van-notice-bar
      style="margin-bottom: 10px"
      wrapable
      :scrollable="false"
      text="公告：
    即日起，耶加阅读付费专栏迁移至此。
    已订阅《英语启蒙妈妈自己在家做》专栏的老用户，用手机号验证码登录即可访问课程。
    由于本人精力有限，课程设置了3年后过期，过期后将无法访问。如果您觉得不合理的话, 可以随时联系我退款。
    暂不支持新用户订阅，谢谢您的支持。
    如果遇到任何问题，请微信联系（微信号langwithkai)。"
    />
    <router-link to="/assets">
      <van-button type="primary" block>已订阅用户点此登录</van-button>
    </router-link>
  </layout>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Layout from "../components/Layout.vue";
import { Button, NoticeBar } from "vant";

export default defineComponent({
  name: "Home",
  components: {
    layout: Layout,
    [Button.name]: Button,
    [NoticeBar.name]: NoticeBar
  }
});
</script>
