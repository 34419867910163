
import { defineComponent } from "vue";
import { useQuery, useResult } from "@vue/apollo-composable";
import { List, Cell, Image, Empty, Skeleton } from "vant";
import Layout from "@/components/Layout.vue";
import { GET_ASSETS } from "@/graphql/query/assets";
import { getAssets } from "@/graphql/generated/getAssets";

export default defineComponent({
  name: "AssetList",
  components: {
    layout: Layout,
    [List.name]: List,
    [Cell.name]: Cell,
    [Image.name]: Image,
    [Empty.name]: Empty,
    [Skeleton.name]: Skeleton
  },
  setup() {
    const { result, loading, error } = useQuery<getAssets>(GET_ASSETS);
    const assets = useResult(result, [], data => data.viewer?.assets);
    return {
      assets,
      loading,
      error
    };
  },
  methods: {
    formatDate(date: string): string {
      return new Date(date).toLocaleDateString("zh-CN");
    }
  }
});
