import gql from "graphql-tag";

export const GET_LESSON = gql`
  query getLesson($id: ID!) {
    lesson(id: $id) {
      id
      coverUrl
      title
      description
      free
      duration
      playUrl
      course {
        id
        title
        coverUrl
        lessons {
          id
        }
      }
    }
  }
`;
